import React, { Component } from "react"
import Layout from "../components/layout"
import Breadcrumb from "../elements/common/Breadcrumb"

class SuccessPage extends Component{
    render(){
        return(
            <Layout>
                <Breadcrumb title={'Thank You'} />

                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        We've got your contact request and someone will reach back to you shortly!
                    </div>
                </div>
            </Layout>
        )
    }
}
export default SuccessPage;